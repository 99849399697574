import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import { extractInnerTextFromHtml } from "utils/formatters"

const ChangeAlternativeModal = ({
  isOpen,
  toggleModal,
  scenarioId,
  alternativeId,
  onSave,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [alternatives, setAlternatives] = useState([])
  const [selectedAlternativeId, setSelectedAlternativeId] = useState(-1)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (isOpen) {
      loadAlternatives()
    }
  }, [scenarioId, isOpen])

  useEffect(() => {
    setSelectedAlternativeId(alternativeId)
  }, [alternativeId])

  const loadAlternatives = async () => {
    if (
      scenarioId > 0 &&
      (alternatives.length == 0 || alternatives[0].scenarioID != scenarioId)
    ) {
      setIsLoading(true)
      let alts = await api.getAlternatives(currentUser.userID, scenarioId)
      setAlternatives(alts)
      setIsLoading(false)
    }
  }

  const save = async () => {
    let alt = alternatives.find(x => x.alternativeID == selectedAlternativeId)
    if (alt != undefined) {
      onSave(selectedAlternativeId, alt.alternativeNum + ' - ' + alt.alternativeName)
      toggleModal()
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      size="md"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Change Alternative
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {isLoading && (
          <Loader
            type="line-scale-pulse-out"
            color={colors.primary}
            style={{ textAlign: "center" }}
          />
        )}
        {!isLoading && (
          <div className="mb-3">
            <Label className="form-label">Alternative</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={selectedAlternativeId}
              onChange={e => {
                setSelectedAlternativeId(e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {alternatives.map((s, i) => (
                <option key={i} value={s.alternativeID}>
                  {s.alternativeNum} - {s.alternativeName} -{" "}
                  {extractInnerTextFromHtml(s.version)}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary save-user"
          onClick={save}
          disabled={selectedAlternativeId <= 0}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}

export default ChangeAlternativeModal
